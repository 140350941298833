import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Button, Grid, IconButton, SwipeableDrawer, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@mui/icons-material/Home';
import { ReactComponent as Logo } from 'src/icons/logo.svg';
import { ReactComponent as ActivityIcon } from 'src/icons/topbar/activity.svg';
import { ReactComponent as KeyIcon } from 'src/icons/topbar/key.svg';
import LinkWithoutStyle from 'src/components/LinkWithoutStyle';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import MenuIcon from '@mui/icons-material/Menu';
import { useMemo, useState } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useWindowDimensions from 'src/hooks/use-window-dimensions';

export const appBarHeightLarge = 64;
export const appBarHeightSmall = 56;

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  padding: 0,
  boxShadow: `0px 4px 6px ${theme.palette.primary.main}40`,
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: `${theme.spacing(1.2)} !important`,
  paddingRight: `${theme.spacing(1.2)} !important`,
}));

const btnSvgSize = 20;

const DesktopButton = styled(Button)(({ theme }) => ({
  '& svg': {
    color: theme.palette.primary.main,
  },
  fontFamily: 'MElleHK-Medium',
  fontSize: 15,
}));

const MobileListItemTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  // fontFamily: 'MElleHK-Medium !important',
  fontFamily: 'MElleHK-Medium',
}));

export default function WebAppBar() {
  const { t } = useTranslation('mainpage');
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const isMdUp = useMediaQuery((theme) => (theme as any).breakpoints.up('md'));
  const is1300Up = useMemo(() => {
    if (width) {
      return width > 1300
    }
  }, [width]);
  const isBetweenMd1300 = useMemo(() => {
    if (is1300Up !== undefined && isMdUp !== undefined) {
      return !is1300Up && isMdUp
    }
  }, [is1300Up, isMdUp])

  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setMobileDrawerOpen(open);
    };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <LinkWithoutStyle to='/'>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon style={{color: theme.palette.primary.main}}/>
              </ListItemIcon>
              <ListItemText primary={ <MobileListItemTypography children={t('topbar.mainpage')}/> } />
            </ListItemButton>
          </ListItem>
        </LinkWithoutStyle>
        <LinkWithoutStyle to='/#formula'>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <KeyIcon width={btnSvgSize} height={btnSvgSize} style={{color: theme.palette.primary.main}}/>
              </ListItemIcon>
              <ListItemText primary={ <MobileListItemTypography children={t('topbar.formula')}/> } />
            </ListItemButton>
          </ListItem>
        </LinkWithoutStyle>
        <LinkWithoutStyle to='/#functionality'>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ActivityIcon width={btnSvgSize} height={btnSvgSize} style={{color: theme.palette.primary.main}}/>
              </ListItemIcon>
              <ListItemText primary={ <MobileListItemTypography children={t('topbar.functionality')}/> } />
            </ListItemButton>
          </ListItem>
        </LinkWithoutStyle>
        <LinkWithoutStyle to='/faq'>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <QuestionMarkIcon style={{color: theme.palette.primary.main}}/>
              </ListItemIcon>
              <ListItemText primary={ <MobileListItemTypography children={t('topbar.faq')}/> } />
            </ListItemButton>
          </ListItem>
        </LinkWithoutStyle>
      </List>
      <Divider />
      <List>
        <a href='https://www.rmshop.com.hk/products/nmn-prime-21000' target='_blank' rel='noreferrer'
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ShoppingCartCheckoutIcon style={{color: theme.palette.primary.main}}/>
              </ListItemIcon>
              <ListItemText primary={ <MobileListItemTypography children={t('topbar.buy_now')}/> } />
            </ListItemButton>
          </ListItem>
        </a>
      </List>
    </Box>
  );

  return (
    <CustomAppBar position='sticky'>
      <CustomToolbar>
        <Grid container
          justifyContent={isBetweenMd1300 ? 'space-between' : 'flex-end'}
          alignItems={'center'}
          sx={{ position: 'relative' }}>
          {isBetweenMd1300 &&
            <Grid item>
              <LinkWithoutStyle to='/'>
                <Logo height={60} />
              </LinkWithoutStyle>
            </Grid>
          }
          {!isBetweenMd1300 &&
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                marginTop: 0.5,
                padding: 'auto',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <LinkWithoutStyle to='/'>
                <Logo height={isMdUp ? 60 : 56} />
              </LinkWithoutStyle>
            </Box>
          }
          {isMdUp &&
            <Grid item container
              justifyContent='flex-end'
              xs={'auto'}
              alignItems='center'
              spacing={1}>
              <Grid item>
                <LinkWithoutStyle to='/'>
                  <DesktopButton variant='text' color='secondary'
                    startIcon={
                      <HomeIcon/>
                    }>
                    <span style={{ color: theme.palette.primary.main }}>
                    {t('topbar.mainpage')}
                    </span>
                  </DesktopButton>
                </LinkWithoutStyle>
              </Grid>
              <Grid item>
                <LinkWithoutStyle to='/#formula'>
                  <DesktopButton variant='text' color='secondary'
                    startIcon={
                      <KeyIcon width={btnSvgSize} height={btnSvgSize}/>
                    }>
                    <span style={{ color: theme.palette.primary.main }}>
                    {t('topbar.formula')}
                    </span>
                  </DesktopButton>
                </LinkWithoutStyle>
              </Grid>
              <Grid item>
                <LinkWithoutStyle to='/#functionality'>
                  <DesktopButton variant='text' color='secondary'
                    startIcon={
                      <ActivityIcon width={btnSvgSize} height={btnSvgSize}/>
                    }>
                    <span style={{ color: theme.palette.primary.main }}>
                    {t('topbar.functionality')}
                    </span>
                  </DesktopButton>
                </LinkWithoutStyle>
              </Grid>
              <Grid item>
                <LinkWithoutStyle to='/faq'>
                  <DesktopButton variant='text' color='secondary'
                    startIcon={
                      <QuestionMarkIcon/>
                    }>
                    <span style={{ color: theme.palette.primary.main }}>
                    {t('topbar.faq')}
                    </span>
                  </DesktopButton>
                </LinkWithoutStyle>
              </Grid>
              <Grid item>
                <a href='https://www.rmshop.com.hk/products/nmn-prime-21000' target='_blank' rel='noreferrer'
                  style={{ textDecoration: 'none', color: 'inherit' }}>
                  <DesktopButton variant='text' color='secondary'
                    startIcon={
                      <ShoppingCartCheckoutIcon/>
                    }>
                    <span style={{ color: theme.palette.primary.main }}>
                    {t('topbar.buy_now')}
                    </span>
                  </DesktopButton>
                </a>
              </Grid>
            </Grid>
          }
          {!isMdUp &&
            <Grid item>
              <IconButton
                color='primary'
                aria-label='mobile device sidebar'
                onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <SwipeableDrawer
                anchor='right'
                open={mobileDrawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}>
                {list()}
              </SwipeableDrawer>
            </Grid>
          }
        </Grid>
      </CustomToolbar>
    </CustomAppBar>
  );
}