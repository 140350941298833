import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
// import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { maxWidthComponent } from 'src/components/homepage/ParallaxEffect1';

const pairNumIdx = Array.from(new Array(18), (_, i) => i);

interface Functionality18Props {
  isFAQ?: boolean,
}

export default function Functionality18({
  isFAQ = false,
}: Functionality18Props) {
  const { t } = useTranslation('mainpage');
  const theme = useTheme();
  const isSmUp = useMediaQuery((theme) => (theme as any).breakpoints.up('sm'));
  // const [currentPic, setCurrentPic] = useState<false | number>(false);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ maxWidth: maxWidthComponent, width: '100%' }}>
        <Box sx={{
          border: `2px solid ${theme.palette.primary.dark}`,
          borderRadius: `30px 30px 0px 0px`,
          backgroundColor: theme.palette.primary.dark,
          padding: 1,
        }}>
          <Typography variant='body1' color='#FFF' textAlign='center'
            sx={{ fontFamily: 'MElleHK-Medium' }}>
            <span style={{ fontSize: '1.7rem', fontWeight: '300' }}>
              {t('18_functionality.text_0') + ' '}
            </span>
            {t('18_functionality.text_1') + ' － '}
            {t('18_functionality.text_2')}
          </Typography>
        </Box>
        {pairNumIdx.map((num: number, idx) => {
          const lastItem = idx === pairNumIdx.length - 1;
          const isEven = idx % 2 === 1;
          return (
            <Box key={`n-a-${num}`}
              sx={{
                borderRight: `2px solid ${theme.palette.primary.dark}`,
                borderLeft: `2px solid ${theme.palette.primary.dark}`,
                borderBottom: lastItem ? `2px solid ${theme.palette.primary.dark}` : 'unset',
                padding: `5px ${isSmUp ? 30 : 15}px`,
                borderRadius: lastItem ? `0px 0px 30px 30px` : '',
                backgroundColor: theme.palette.primary[isEven ? 'light' : 'main'],
                // cursor: 'pointer',
                position: 'relative',
              }}
              // onMouseEnter={() => setCurrentPic(idx)}
              // onMouseLeave={() => setCurrentPic(false)}
              >
              <Grid container>
                <Grid item xs={3}>
                  <Typography color={isEven ? 'primary.dark' : '#FFF'}
                    sx={{ fontFamily: 'MElleHK-Medium' }}>
                    {t(`18_functionality.pair_${idx}.part` as any)}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography color={isEven ? 'primary.dark' : '#FFF'}
                    sx={{ fontFamily: 'MElleHK-Medium' }}>
                    {t(`18_functionality.pair_${idx}.function` as any)}
                  </Typography>
                </Grid>
              </Grid>
              {/* {!isSmUp &&
                <img src={`/picture/18-functionalities/${idx}.jpeg`}
                  style={{
                    width: '100%',
                    borderRadius: 30,
                    // boxShadow: `0px 0px 12px ${theme.palette.primary.dark}`,
                    maxWidth: 600,
                    margin: '5px 0px',
                  }}
                  alt={`${idx}-th-functionaly`}
                />
              }
              {isSmUp &&
                <img src={`/picture/18-functionalities/${idx}.jpeg`}
                  style={{
                    width: '100%',
                    borderRadius: 30,
                    boxShadow: `0px 0px 12px ${theme.palette.primary.dark}`,
                    maxWidth: 600,
                    margin: '5px 0px',
                    position: 'absolute',
                    // top: 'calc(100% + 20px)',
                    // left: 0,
                    top: isFAQ ? 'calc(100% + 60px)' : 20,
                    right: isFAQ ? 0 : 'calc(-100% + 20px)',
                    zIndex: 2,
                    visibility:  currentPic === idx ? 'visible' : 'hidden',
                  }}
                  alt={`${idx}-th-functionaly`}
                />
              } */}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}