import {
  // Container,
  Grid, Typography, useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';
// import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { Parallax } from 'react-scroll-parallax';
import Functionality18 from 'src/components/Functionality18';
import { mainpageMaxWidth, ParallaxSandwichContainer } from 'src/pages/Mainpage';
import ConcernGroup from './ConcernGroup';

export const maxWidthComponent = 700;
export const maxComponentSx = {
  maxWidth: {
    lg: `${maxWidthComponent}px !important`
  },
  margin: {
    lg: 'auto'
  },
};

export default function ParallaxEffect1() {
  const { t } = useTranslation('mainpage');
  const theme = useTheme();
  const isMdUp = useMediaQuery((theme) => (theme as any).breakpoints.up('md'));

  const maxContainerSx = useMemo(() => ({
    maxWidth: {
      lg: `${mainpageMaxWidth}px !important`
    },
    margin: {
      lg: 'auto'
    },
  }), []);

  return (
    <ParallaxSandwichContainer maxWidth={false}
      sx={{
        // margin: `${isMdUp ? 20: 20}px 0px`,
        ...maxContainerSx,
        paddingTop: isMdUp ? 6 : 3,
        paddingBottom: isMdUp ? 6 : 3,
      }}>
      <Grid item container>
        <Grid item xs={12} md={6}>
          {/* <Parallax speed={isMdUp ? -15 : 0}> */}
            <Functionality18 />
          {/* </Parallax> */}
        </Grid>
        <Grid item xs={12} md={6}
          sx={{
            padding: '0px 10px',
            marginTop: isMdUp ? 0 : 4,
          }}>
          {/* <Parallax speed={isMdUp ? 20 : 0}> */}
            <Grid item container
              direction='column'
              spacing={1}
              padding={1}
              xs={12}
              sx={{ ...maxComponentSx }}>
              <Grid item xs={8}>
                <Typography variant='h6' textAlign='left' color='primary.dark'
                  sx={{
                    fontFamily: 'MElleHK-Medium',
                    borderBottom: `2.5px solid ${theme.palette.primary.dark}`,
                  }}>
                  {t('paragraph.pair_0.title')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' textAlign='left' color='primary.main'
                  sx={{ fontFamily: 'MElleHK-Medium' }}>
                  {t('paragraph.pair_0.text_0')}
                  <span style={{
                    fontFamily: 'Noto Sans CJK TC,Roboto,Arial,sans-serif',
                    fontWeight: 300,
                  }}>
                    {t('paragraph.pair_0.special')}
                  </span>
                  {t('paragraph.pair_0.text_1')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='h6' textAlign='left' color='primary.dark'
                  sx={{
                    fontFamily: 'MElleHK-Medium',
                    borderBottom: `2.5px solid ${theme.palette.primary.dark}`,
                  }}>
                  {t('paragraph.pair_1.title')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' textAlign='left' color='primary.main'
                  sx={{ fontFamily: 'MElleHK-Medium' }}>
                  {t('paragraph.pair_1.text')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='h6' textAlign='left' color='primary.dark'
                  sx={{
                    fontFamily: 'MElleHK-Medium',
                    borderBottom: `2.5px solid ${theme.palette.primary.dark}`,
                  }}>
                  {t('paragraph.pair_2.title')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' textAlign='left' color='primary.main'
                  sx={{ fontFamily: 'MElleHK-Medium' }}>
                  {t('paragraph.pair_2.text')}
                  {' '}
                  <span style={{
                    fontFamily: 'Noto Sans CJK TC,Roboto,Arial,sans-serif',
                    fontWeight: 300,
                  }}>
                    {t('paragraph.pair_2.special')}
                  </span>

                </Typography>
              </Grid>

              <Grid item xs={12}
                sx={{ marginTop: { xs: 0, sm: 4, xl: 6 } }}>
                <ConcernGroup md={12}/>
              </Grid>
            </Grid>
          {/* </Parallax> */}
        </Grid>
      </Grid>
    </ParallaxSandwichContainer>
  )
}