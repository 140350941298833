import { Box, Grid, styled, Typography, useMediaQuery,
  // useTheme
} from '@mui/material';
import React from 'react';
// import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FadeInAnimation from 'src/components/animation/FadeInAnimation';
import { ReactComponent as GinSengSvg } from 'src/icons/ginseng.svg';
import { ReactComponent as GrapeSvg } from 'src/icons/grape.svg';
import { ReactComponent as DnaSvg } from 'src/icons/dna.svg';

const iconWidth = 150;

const FormulaBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export default function Formula() {
  const { t } = useTranslation('mainpage');
  // const theme = useTheme();
  const isSmUp = useMediaQuery((theme) => (theme as any).breakpoints.up('sm'));
  // const [currentPic, setCurrentPic] = useState<'dna' | 'ginseng' | 'grape'>('ginseng');

  return (
    <React.Fragment>
      <Grid item container justifyContent='center'>
        <Grid item container md={3} justifyContent='center'
          sx={{
            padding: 1,
            // cursor: 'pointer',
          }}
          // onMouseEnter={() => setCurrentPic('dna')}
          >
          <FadeInAnimation style={{ transitionDelay: '0.5s' }}>
            <FormulaBox>
              <DnaSvg height={iconWidth} width={iconWidth}/>
              <Typography variant='h6' color='secondary.dark' textAlign='center'
                sx={{ fontFamily: 'MElleHK-Light' }}>
                {t('mainpage.formula.material_1.title')}
              </Typography>
              <Typography variant='body1' color='secondary.dark' textAlign='center'
                sx={{ fontFamily: 'MElleHK-Medium' }}>
                {t('mainpage.formula.material_1.text_0')}
              </Typography>
              <Typography variant='body1' color='secondary.dark' textAlign='center'
                sx={{ fontFamily: 'MElleHK-Medium' }}>
                {t('mainpage.formula.material_1.text_1')}
              </Typography>
              {/* {!isSmUp &&
                <img src={`/picture/formula/dna.jpeg`}
                  style={{
                    width: '100%',
                    borderRadius: 30,
                    boxShadow: `0px 0px 12px ${theme.palette.primary.dark}`,
                    marginTop: 10,
                    marginBottom: 20,
                  }}
                  alt='dna'
                />
              } */}
            </FormulaBox>
          </FadeInAnimation>
        </Grid>
        <Grid item container md={3} justifyContent='center'
          sx={{
            padding: 1,
            // cursor: 'pointer',
          }}
          // onMouseEnter={() => setCurrentPic('ginseng')}
          >
          <FadeInAnimation style={{ transitionDelay: '0.7s' }}>
            <FormulaBox>
              <GinSengSvg height={iconWidth} width={iconWidth}/>
              <Typography variant='h6' color='secondary.dark' textAlign='center'
                sx={{ fontFamily: 'MElleHK-Light' }}>
                {t('mainpage.formula.material_0.title')}
              </Typography>
              <Typography variant='body1' color='secondary.dark' textAlign='center'
                sx={{ fontFamily: 'MElleHK-Medium' }}>
                {t('mainpage.formula.material_0.text_0')}
              </Typography>
              <Typography variant='body1' color='secondary.dark' textAlign='center'
                sx={{ fontFamily: 'MElleHK-Medium' }}>
                {t('mainpage.formula.material_0.text_1')}
              </Typography>
              {/* {!isSmUp &&
                <img src={`/picture/formula/ginseng.jpeg`}
                  style={{
                    width: '100%',
                    borderRadius: 30,
                    boxShadow: `0px 0px 12px ${theme.palette.primary.dark}`,
                    marginTop: 10,
                    marginBottom: 20,
                  }}
                  alt='grape'
                />
              } */}
            </FormulaBox>
          </FadeInAnimation>
        </Grid>
        <Grid item container md={3} justifyContent='center'
          sx={{
            padding: 1,
            // cursor: 'pointer',
          }}
          // onMouseEnter={() => setCurrentPic('grape')}
          >
          <FadeInAnimation style={{ transitionDelay: '0.7s' }}>
            <FormulaBox>
              <GrapeSvg height={iconWidth} width={iconWidth}/>
              <Typography variant='h6' color='secondary.dark' textAlign='center'
                sx={{ fontFamily: 'MElleHK-Light' }}>
                {t('mainpage.formula.material_2.title')}
              </Typography>
              <Typography variant='body1' color='secondary.dark' textAlign='center'
                sx={{ fontFamily: 'MElleHK-Medium' }}>
                {t('mainpage.formula.material_2.text_0')}
              </Typography>
              <Typography variant='body1' color='secondary.dark' textAlign='center'
                sx={{ fontFamily: 'MElleHK-Medium' }}>
                {t('mainpage.formula.material_2.text_1')}
              </Typography>
              {/* {!isSmUp &&
                <img src={`/picture/formula/grape.jpeg`}
                  style={{
                    width: '100%',
                    borderRadius: 30,
                    boxShadow: `0px 0px 12px ${theme.palette.primary.dark}`,
                    marginTop: 10,
                    marginBottom: 20,
                  }}
                  alt='grape'
                />
              } */}
            </FormulaBox>
          </FadeInAnimation>
        </Grid>
      </Grid>
      {/* {isSmUp &&
        <Grid item container justifyContent='center'>
          <FadeInAnimation style={{ transitionDelay: '0.3s' }}>
            <img src={`/picture/formula/${currentPic}.jpeg`}
              style={{
                width: '100%',
                borderRadius: 30,
                boxShadow: `0px 0px 12px ${theme.palette.primary.dark}`,
                maxWidth: 600,
              }}
              alt={currentPic}
            />
          </FadeInAnimation>
        </Grid>
      } */}
      <Grid item container justifyContent='center'>
        <Grid item xs={9}>
          <Typography variant={'body2'} color='primary.dark'
            textAlign={'right'}
            sx={{ fontSize: isSmUp ? '0.875rem' : '0.6rem' }}>
            {t('mainpage.formula.supplement_0')}
          </Typography>
          <Typography variant={'body2'} color='primary.dark'
            textAlign={'right'}
            sx={{ fontSize: isSmUp ? '0.875rem' : '0.6rem' }}>
            {t('mainpage.formula.supplement_1')}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}