import { Box, Grid, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as LogoDark } from 'src/icons/logo_dark.svg';
import { ReactComponent as Logo } from 'src/icons/logo.svg';
import { ReactComponent as FbIcon } from 'src/icons/social_media/fb.svg';
import { ReactComponent as IgIcon } from 'src/icons/social_media/ig.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useMemo } from 'react';

const logoColor = '#AA787D';

const FooterContainer = styled(Box)(({ theme }) => ({
  minHeight: 150,
  paddingTop: 20,
  paddingBottom: 20,
  boxShadow: `0px -4px 6px ${theme.palette.primary.main}40`,
  // for parallax effect
  zIndex: 2,
  position: 'relative',
}));


const SocialMediaWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '5px 10px',
  marginBottom: 11,
  borderRadius: 10,
  boxShadow: `5px 5px 10px ${theme.palette.primary.main}`
}));

// const SocialMediaSeparatorPrimaryMain = styled('div')(({ theme }) => ({
//   width: 3,
//   height: socialMediaSvgSize + 10,
//   marginLeft: 5,
//   marginRight: 8,
//   backgroundColor: theme.palette.primary.main,
// }));

// const SocialMediaSeparatorTertiaryDark = styled('div')(({ theme }) => ({
//   width: 3,
//   height: socialMediaSvgSize + 10,
//   marginLeft: 5,
//   marginRight: 8,
//   backgroundColor: theme.palette.tertiary.dark,
// }));

const SocialMediaSeparatorSameColor = styled('div')(({ theme }) => ({
  width: 3,
  marginLeft: 5,
  marginRight: 8,
  backgroundColor: logoColor,
}));

const FooterText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
}));

export default function Footer() {
  const theme = useTheme();
  const { t } = useTranslation('mainpage');
  const isSmUp = useMediaQuery((theme) => (theme as any).breakpoints.up('sm'));
  
  const socialMediaSvgSize = useMemo(() => isSmUp ? 26 : 20, [isSmUp]);

  return (
    <FooterContainer>
      {/* 1st design footer */}
      {/* <Grid item container
        justifyContent='center'
        alignItems='center'
        xs={12} spacing={2}>
        <Grid item>
          <a href='https://royalmedic.com.hk/zh-hk' target='_blank' rel='noreferrer'>
            <LogoDark height={80} />
          </a>
        </Grid>
        <Grid item>
          <a href='https://www.facebook.com/RoyalMedicHK/' target='_blank' rel='noreferrer'
            style={{ textDecoration: 'none', color: 'inherit' }}>
            <SocialMediaWrapper>
              <FbIcon height={socialMediaSvgSize} width={socialMediaSvgSize}/>
              <SocialMediaSeparatorPrimaryMain />
              <Typography variant='h6' color='primary.main'>
                {t('footer.fb')}
              </Typography>
            </SocialMediaWrapper>
          </a>
        </Grid>
        <Grid item>
          <a href='https://www.instagram.com/hkroyalmedic/' target='_blank' rel='noreferrer'
            style={{ textDecoration: 'none', color: 'inherit' }}>
            <SocialMediaWrapper>
              <IgIcon height={socialMediaSvgSize} width={socialMediaSvgSize}/>
              <SocialMediaSeparatorPrimaryMain />
              <Typography variant='h6' color='primary.main'>
                {t('footer.ig')}
              </Typography>
            </SocialMediaWrapper>
          </a>
        </Grid>
      </Grid> */}
      {/* 1st design footer */}

      {/* 2nd design footer */}
      {/* <Grid item container
        justifyContent='center'
        alignItems='center'
        xs={12} spacing={2}>
        <Grid item>
          <a href='https://royalmedic.com.hk/zh-hk' target='_blank' rel='noreferrer'>
            <Logo height={80} />
          </a>
        </Grid>
        <Grid item>
          <a href='https://www.facebook.com/RoyalMedicHK/' target='_blank' rel='noreferrer'
            style={{ textDecoration: 'none', color: 'inherit' }}>
            <SocialMediaWrapper>
              <FbIcon height={socialMediaSvgSize} width={socialMediaSvgSize}/>
              <SocialMediaSeparatorTertiaryDark />
              <Typography variant='h6' color='primary.main'>
                {t('footer.fb')}
              </Typography>
            </SocialMediaWrapper>
          </a>
        </Grid>
        <Grid item>
          <a href='https://www.instagram.com/hkroyalmedic/' target='_blank' rel='noreferrer'
            style={{ textDecoration: 'none', color: 'inherit' }}>
            <SocialMediaWrapper>
              <IgIcon height={socialMediaSvgSize} width={socialMediaSvgSize}/>
              <SocialMediaSeparatorTertiaryDark />
              <Typography variant='h6' color='primary.main'>
                {t('footer.ig')}
              </Typography>
            </SocialMediaWrapper>
          </a>
        </Grid>
      </Grid> */}
      {/* 2nd design footer */}
      
      {/* 3rd design footer */}
      <Grid item container
        justifyContent='center'
        alignItems='center'
        xs={12}
        spacing={isSmUp ? 2 : 1}
        direction={isSmUp ? 'row' : 'column'}>
        <Grid item>
          <a href='https://royalmedic.com.hk/zh-hk' target='_blank' rel='noreferrer'>
            <Logo height={80} />
          </a>
        </Grid>
        <Grid item>
          <a href='https://www.facebook.com/RoyalMedicHK/' target='_blank' rel='noreferrer'
            style={{ textDecoration: 'none', color: 'inherit' }}>
            <SocialMediaWrapper>
              <FbIcon height={socialMediaSvgSize} width={socialMediaSvgSize} fill={logoColor}/>
              <SocialMediaSeparatorSameColor sx={{ height: socialMediaSvgSize + 10 }}/>
              <Typography variant={isSmUp ? 'h6' : 'body1'} color={logoColor}
                sx={{ fontFamily: 'MElleHK-Medium' }}>
                {t('footer.fb')}
              </Typography>
            </SocialMediaWrapper>
          </a>
        </Grid>
        <Grid item>
          <a href='https://www.instagram.com/hkroyalmedic/' target='_blank' rel='noreferrer'
            style={{ textDecoration: 'none', color: 'inherit' }}>
            <SocialMediaWrapper>
              <IgIcon height={socialMediaSvgSize} width={socialMediaSvgSize} fill={logoColor}/>
              <SocialMediaSeparatorSameColor sx={{ height: socialMediaSvgSize + 10 }}/>
              <Typography variant={isSmUp ? 'h6' : 'body1'} color={logoColor}
                sx={{ fontFamily: 'MElleHK-Medium' }}>
                {t('footer.ig')}
              </Typography>
            </SocialMediaWrapper>
          </a>
        </Grid>
      </Grid>
      {/* 3rd design footer */}

      <Grid item container
        justifyContent='center'
        alignItems='flex-end'
        sx={{
          height: isSmUp ? 25 : 45,
          marginTop: 2,
        }}>
        <Grid item>
          {isSmUp &&
            <Typography variant='body2' color={theme.palette.secondary.dark}>
              {t('footer.copyright.0')}
              {' | '}
              <span style={{ color: theme.palette.primary.main}}>
                {t('footer.copyright.1')}
              </span>
            </Typography>
          }
          {!isSmUp &&
            <React.Fragment>
              <FooterText variant='body2' color={theme.palette.secondary.dark}>
                {t('footer.copyright.0')}
              </FooterText>
              <FooterText variant='body2' color={theme.palette.primary.main}>
                {t('footer.copyright.1')}
              </FooterText>
            </React.Fragment>
          }
        </Grid>
      </Grid>
    </FooterContainer>
  );
}