import { Button, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FadeInAnimation from 'src/components/animation/FadeInAnimation';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

export default function NotFoundPage() {
  const { t } = useTranslation('mainpage');
  const theme = useTheme();
  const isSmUp = useMediaQuery((theme) => (theme as any).breakpoints.up('sm'));

  return (
    <Container sx={{ paddingTop: 2, paddingBottom: 2, minHeight: 'calc(100vh - 64px - 190px)' }}>
      <Grid
        item container
        xs={12}
        spacing={3}
        direction='column'
        justifyContent='center'
        alignItems='center'>

        <Grid item>
          <FadeInAnimation>
            <Typography variant='h4' color='primary.main'
              sx={{ fontWeight: 'normal' }} >
              {t('404.title')}
            </Typography>
          </FadeInAnimation>
        </Grid>

        <Grid item container justifyContent='center'>
          <FadeInAnimation>
            <Typography variant={`${isSmUp ? 'h5' : 'body1'}`} color='primary.dark'
              style={{ fontWeight: 300 }}>
              {t('mainpage.invite')}
            </Typography>
          </FadeInAnimation>
        </Grid>

        <Grid item container justifyContent='center'>
          <FadeInAnimation>
            <a href='https://www.rmshop.com.hk/products/nmn-prime-21000' target='_blank' rel='noreferrer'
              style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button variant='text' color='secondary'
                size='large'
                startIcon={
                  <ShoppingCartCheckoutIcon/>
                }
                sx={{ '& svg': {
                  color: theme.palette.primary.main,
                }}}>
                <span style={{ color: theme.palette.primary.main }}>
                {t('topbar.buy_now')}
                </span>
              </Button>
            </a>
          </FadeInAnimation>
        </Grid>

        <Grid item>
          <FadeInAnimation>
            <img src={isSmUp ? '/picture/full_banner_desktop.jpg' : '/picture/full_banner_mobile.jpg'}
              alt='product-shot'
              style={{ width: '100%' }} />
          </FadeInAnimation>
        </Grid>
        
      </Grid>
    </Container>
  )
}