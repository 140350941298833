import { Box, Grid, styled, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FadeInAnimation from 'src/components/animation/FadeInAnimation';
import FadeInOutAnimation from 'src/components/animation/FadeInAnimation';
import React from 'react';
import Functionality18 from 'src/components/Functionality18';
import NaturalAmountTable from 'src/components/FAQ/NaturalAmountTable';
// import { getSrcSet, imgSizes } from 'src/utils/img-attr';
import ImgResponsive from 'src/components/ImgResponsive';

const TitleSeparatorShort = styled('div')(({ theme }) => ({
  width: '70%',
  height: 2,
  backgroundColor: theme.palette.primary.dark,
}));

export default function FAQ() {
  const { t } = useTranslation('mainpage');
  const isSmUp = useMediaQuery((theme) => (theme as any).breakpoints.up('sm'));

  return (
    <Box sx={{
      // paddingTop: 2,
      paddingBottom: isSmUp ? 6 : 2,
      minHeight: 'calc(100vh - 64px - 190px)',
      }}>
      <Grid
        item container
        xs={12}
        spacing={4}
        direction='column'
        justifyContent='center'
        alignItems='center'>

        <Grid item>
          <FadeInAnimation>
            {/* manual srcset */}
            <ImgResponsive
              src={'/picture/full_banner_desktop.jpg'}
              alt='product-shot'
              style={{ width: '100%' }} />
          </FadeInAnimation>
        </Grid>

        <Grid item>
          <FadeInOutAnimation style={{ transitionDelay: '0.2s' }}>
            <Typography variant={isSmUp ? 'h3' : 'body1'} color='primary.main'
              sx={{ fontFamily: 'MElleHK-Medium' }}>
              {t('faq.title')}
            </Typography>
          </FadeInOutAnimation>
        </Grid>

        {Array.from(new Array(11), (_, i) => i).map((num: number, idx) => {
            // const isEven = idx % 2 === 1;
            const isQuestionDouble = [3, 4, 9, 10].includes(num);

            const isAnswerDouble = [5, 7, 8].includes(num);
            const isAnswerTriple = [3].includes(num);
            const answerList = Array.from(new Array(isAnswerTriple ? 3 : (isAnswerDouble ? 2 : 1)), (_, i) => i);

            const hasSupplementText = t((`faq.pair_${num}.supplementary` as any)) !== `faq.pair_${num}.supplementary`;

            return (
              <Grid item container
                xs={10} sm={8}
                spacing={0.5}
                justifyContent='center'
                key={`faq-${idx}`}
                sx={{ zIndex: idx === 5 ? 1 : 0 }}
                >
                <Grid item xs={10} sm={8}>
                  <FadeInOutAnimation style={{ transitionDelay: idx === 0 ? '0.2s' : '0s' }}>
                    {!isQuestionDouble &&
                      <Typography variant={isSmUp ? 'h4' : 'body1'} color='secondary.main'
                        // textAlign={isEven ? 'right' : 'left'}>
                        textAlign={'left'}
                        sx={{ fontFamily: 'MElleHK-Light' }}>
                        {t(`faq.pair_${num}.question` as any)}
                      </Typography>
                    }
                    {isQuestionDouble &&
                      <React.Fragment>
                        <Typography variant={isSmUp ? 'h4' : 'body1'} color='secondary.main'
                          // textAlign={isEven ? 'right' : 'left'}>
                          textAlign={'left'}
                          sx={{ fontFamily: 'MElleHK-Light' }}>
                          {t(`faq.pair_${num}.question_0` as any)}
                        </Typography>
                        <Typography variant={isSmUp ? 'h4' : 'body1'} color='secondary.main'
                          // textAlign={isEven ? 'right' : 'left'}>
                          textAlign={'left'}
                          sx={{ fontFamily: 'MElleHK-Light' }}>
                          {t(`faq.pair_${num}.question_1` as any)}
                        </Typography>
                      </React.Fragment>
                    }
                  </FadeInOutAnimation>
                </Grid>
                <Grid item xs={10} sm={8}>
                  <FadeInOutAnimation style={{ transitionDelay: idx === 0 ? '0.2s' : '0s' }}>
                    <TitleSeparatorShort />
                  </FadeInOutAnimation>
                </Grid>
                <Grid item xs={10} sm={8}>
                  <FadeInOutAnimation style={{ transitionDelay: idx === 0 ? '0.2s' : '0s' }}>
                    <React.Fragment>
                      {answerList.map(i => (
                        <Typography variant={isSmUp ? 'h6' : 'body2'} color='primary.dark' key={`Q${idx}-answer-${i}`}
                          sx={{ marginTop: 1 }}>
                          {t(`faq.pair_${num}.answer_${i}` as any)}
                        </Typography>
                      ))}
                    </React.Fragment>
                  </FadeInOutAnimation>
                </Grid>
                {idx === 5 && 
                  <Grid item xs={11} sm={8}>
                    <FadeInOutAnimation style={{ transitionDelay: '0s', marginTop: 10 }}>
                      <Functionality18 isFAQ/>
                    </FadeInOutAnimation>
                  </Grid>
                }
                {idx === 9 && 
                  <Grid item xs={10} sm={8}>
                    <FadeInOutAnimation style={{ transitionDelay: '0s', marginTop: 10 }}>
                      <NaturalAmountTable />
                    </FadeInOutAnimation>
                  </Grid>
                }
                {hasSupplementText &&
                  <Grid item xs={10} sm={8}>
                    <FadeInOutAnimation style={{ transitionDelay: '0s', marginTop: 10 }}>
                      <Typography variant={'body2'} color='primary.dark'
                        textAlign={'right'}
                        sx={{ marginTop: 1, fontSize: isSmUp ? '0.875rem' : '0.6rem' }}>
                        {t(`faq.pair_${num}.supplementary` as any)}
                      </Typography>
                    </FadeInOutAnimation>
                  </Grid>
                }
                {/* <Grid item xs={10} sm={8}>
                  <FadeInOutAnimation style={{ transitionDelay: idx === 0 ? '0.2s' : '0s' }}>
                    <TitleSeparatorFull />
                  </FadeInOutAnimation>
                </Grid> */}
              </Grid>
            )
          }
        )}
      </Grid>
    </Box>
  )
}