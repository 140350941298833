import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const pairNumIdx = Array.from(new Array(10), (_, i) => i);

export default function NaturalAmountTable() {
  const { t } = useTranslation('mainpage');
  const theme = useTheme();
  const isSmUp = useMediaQuery((theme) => (theme as any).breakpoints.up('sm'));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ maxWidth: 600, width: '100%' }}>
        <Box sx={{
          border: `2px solid ${theme.palette.primary.dark}`,
          borderRadius: `30px 30px 0px 0px`,
          backgroundColor: theme.palette.primary.dark,
          padding: 1,
        }}>
          <Typography variant='body1' color='#FFF' textAlign='center'
            sx={{ fontFamily: 'MElleHK-Medium' }}>
            {t('nmn_natural.title')}
          </Typography>
        </Box>

        <Box
          sx={{
            borderRight: `2px solid ${theme.palette.primary.dark}`,
            borderLeft: `2px solid ${theme.palette.primary.dark}`,
            borderBottom: 'unset',
            padding: `5px ${isSmUp ? 30 : 15}px`,
            borderRadius: 'unset',
            backgroundColor: theme.palette.secondary.dark,
          }}>
          <Grid container>
            <Grid item container xs={4} alignItems='flex-end'>
              <Typography color={'#FFF'} fontSize={!isSmUp ? '0.85rem' : undefined}
                sx={{ fontFamily: 'MElleHK-Medium' }}>
                {t(`nmn_natural.food_type`)}
              </Typography>
            </Grid>
            <Grid item container xs={4} alignItems='flex-end'>
              <Typography color={'#FFF'} fontSize={!isSmUp ? '0.85rem' : undefined}
                sx={{ fontFamily: 'MElleHK-Medium' }}>
                {t(`nmn_natural.food_name`)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color={'#FFF'} fontSize={!isSmUp ? '0.85rem' : undefined}
                sx={{ fontFamily: 'MElleHK-Medium' }}>
                {t(`nmn_natural.amount_0`)}
                <br/>
                {t(`nmn_natural.amount_1`)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {pairNumIdx.map((num: number, idx) => {
          const lastItem = idx === pairNumIdx.length - 1;
          const isEven = idx % 2 === 1;
          return (
            <Box key={`n-a-${num}`}
              sx={{
                borderRight: `2px solid ${theme.palette.primary.dark}`,
                borderLeft: `2px solid ${theme.palette.primary.dark}`,
                borderBottom: lastItem ? `2px solid ${theme.palette.primary.dark}` : 'unset',
                padding: `5px ${isSmUp ? 30 : 15}px`,
                borderRadius: lastItem ? `0px 0px 30px 30px` : '',
                backgroundColor: theme.palette.primary[isEven ? 'light' : 'main'],
              }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography color={isEven ? 'primary.dark' : '#FFF'}
                    sx={{ fontFamily: 'MElleHK-Light' }}>
                    {t(`nmn_natural.pair.${idx}.type` as any)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color={isEven ? 'primary.dark' : '#FFF'}
                    sx={{ fontFamily: 'MElleHK-Light' }}>
                    {t(`nmn_natural.pair.${idx}.name` as any)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color={isEven ? 'primary.dark' : '#FFF'}
                    sx={{ fontFamily: 'MElleHK-Light' }}>
                    {t(`nmn_natural.pair.${idx}.amount` as any)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}