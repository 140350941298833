import {
  Grid, Typography, useMediaQuery,
  useTheme,
} from '@mui/material';
// import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import MuiFade from 'src/components/animation/MuiFade';

import { ReactComponent as FunSvg0 } from 'src/icons/function/fun_0.svg';
import { ReactComponent as FunSvg1 } from 'src/icons/function/fun_1.svg';
import { ReactComponent as FunSvg2 } from 'src/icons/function/fun_2.svg';
import { ReactComponent as FunSvg3 } from 'src/icons/function/fun_3.svg';
import { ReactComponent as FunSvg4 } from 'src/icons/function/fun_4.svg';
import { ReactComponent as FunSvg5 } from 'src/icons/function/fun_5.svg';
import { ReactComponent as FunSvg6 } from 'src/icons/function/fun_6.svg';
import FadeInAnimation from 'src/components/animation/FadeInAnimation';

const funSvgHeight = 130;
const funSvgList = [
  <FunSvg0 height={funSvgHeight} width={funSvgHeight}/>,
  <FunSvg1 height={funSvgHeight} width={funSvgHeight}/>,
  <FunSvg2 height={funSvgHeight} width={funSvgHeight}/>,
  <FunSvg3 height={funSvgHeight} width={funSvgHeight}/>,
  <FunSvg4 height={funSvgHeight} width={funSvgHeight}/>,
  <FunSvg5 height={funSvgHeight} width={funSvgHeight}/>,
  <FunSvg6 height={funSvgHeight} width={funSvgHeight}/>,
];

export default function FunIcon() {
  const { t } = useTranslation('mainpage');
  const theme = useTheme();
  const isSmUp = useMediaQuery((theme) => (theme as any).breakpoints.up('sm'));
  // const [currentPic, setCurrentPic] = useState<number>(0);
    
  return (
    // <MuiFade enter={3000}>
      <Grid item container alignItems='flex-start'
        justifyContent='center'>
        {funSvgList.map((item, idx) => {
          return (
            <Grid item key={`fun-${idx}`}
              xs={12}
              sm={4}
              md={3}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 1,
                paddingLeft: 1,
                paddingRight: 1,
                transition: 'all 0.2s ease-out',
                // cursor: 'pointer',
                scale: '0.9',
                ':hover': {
                  scale: '1',
                  textShadow: `1px 1px ${theme.palette.primary.main}20`,
                  '& path': {
                    filter: `drop-shadow(0.5px 0.5px 0.4px ${theme.palette.primary.main})`,
                  },
                },
              }}
              // onMouseEnter={() => setCurrentPic(idx)}
              >
              <FadeInAnimation>
                {item}
              </FadeInAnimation>
              <FadeInAnimation>
                <Typography variant={isSmUp ? 'h6' : 'body1'}
                  color='primary.dark'
                  textAlign='center'
                  sx={{ maxWidth: 200, fontFamily: 'MElleHK-Medium' }}>
                  {t(`mainpage.function.fun_${idx}` as any)}
                </Typography>
              </FadeInAnimation>
              {/* {!isSmUp &&
                <img src={`/picture/fun/${idx}.jpeg`}
                  style={{
                    width: '100%',
                    borderRadius: 30,
                    boxShadow: `0px 0px 12px ${theme.palette.primary.dark}`,
                    marginTop: 10,
                    marginBottom: 25,
                  }}
                  alt={`demo-for-${idx}-th-functionality`}
                />
              } */}
            </Grid>
          )
        })}
        {/* {isSmUp &&
          <Grid item container justifyContent='center'>
            <FadeInAnimation style={{ transitionDelay: '0.3s' }}>
              <img src={`/picture/fun/${currentPic}.jpeg`}
                style={{
                  height: '50vh',
                  borderRadius: 30,
                  boxShadow: `0px 0px 12px ${theme.palette.primary.dark}`,
                  marginTop: 10,
                }}
                alt={`demo-for-${currentPic}-th-functionality`}
              />
            </FadeInAnimation>
          </Grid>
        } */}
      </Grid>
    // </MuiFade>
  )
}