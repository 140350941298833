import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
// import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import MuiFade from 'src/components/animation/MuiFade';
import FadeInAnimation from 'src/components/animation/FadeInAnimation';

export default function ConcernGroup({
  md = 6,
}) {
  const { t } = useTranslation('mainpage');
  const theme = useTheme();
  const isSmUp = useMediaQuery((theme) => (theme as any).breakpoints.up('sm'));
  // const [hoverNum, setHoverNum] = useState(0);
  return (
    // <MuiFade enter={3000}>
      <Grid item container xs={12} // md={md}
        // justifyContent='center'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={1}
        sx={{ marginTop: isSmUp ? 0 : 2 }}>
          
        <Grid item xs={12}>
          <FadeInAnimation>
            <Box sx={{
              backgroundColor: theme.palette.primary.dark,
              borderRight: `6px solid ${theme.palette.primary.main}`,
              borderLeft: `6px solid ${theme.palette.primary.main}`,
              // width: 'fit-content',
              // width: '100%',
              padding: {
                xs: '5px 0px',
                sm: '5px 40px',
              }
            }}>
              <Typography variant='h5' color='#FFF' textAlign='center'
                sx={{
                  // width: 'fit-content',
                  fontFamily: 'MElleHK-Medium',
                }} >
                {t('concern_group.subtitle')}
              </Typography>
            </Box>
          </FadeInAnimation>
        </Grid>

        {Array.from(new Array(7), (_, i) => i + 1).map((num: number, idx) => {
            return (
              <Grid item container
                xs={12} sm={6}
                justifyContent='center'
                key={`group-${idx}`}
                sx={{
                  // backgroundColor: `#${theme.palette.primary[idx % 2 === 1 ? 'main' : 'dark']}40`
                  // cursor: 'pointer',
                  
                }}
                // onMouseEnter={() => setHoverNum(idx)}
                >
                <Grid item xs={12} //xs={10} sm={6}
                  >
                  <FadeInAnimation>
                    <Box sx={{
                      display: 'flex',
                      padding: {
                        xs: '0px 10px',
                        sm: '0px 20px',
                        md: '0px 20px',
                        lg: '0px 20px',
                        xl: '0px 50px',
                      }
                    }}>
                    <span
                      style={{
                        marginTop: 1,
                        marginRight: 5,
                        fontSize: 20,
                        color: theme.palette.primary.dark,
                      }}>
                      {'· '}
                    </span>
                    <Typography color='primary.dark'
                      sx={{ fontFamily: 'MElleHK-Medium' }}>
                      <span style={{ textAlign: 'start' }}>
                        {t(`concern_group.group.${num}` as any)}
                      </span>
                    </Typography>
                    </Box>
                  </FadeInAnimation>
                </Grid>
                {/* {!isSmUp &&
                  <Grid item xs={11}>
                    <img src={`/picture/concern-group/${idx}.jpeg`}
                      style={{ width: '100%', margin: '10px 0px' }}
                      alt={`concern-group-${idx}`} />
                  </Grid>
                } */}
              </Grid>
            )
          }
        )}
        {/* <Grid item xs={10} sm={8}> */}
        {/* {isSmUp &&
          <Grid item container xs={12} sm={11} justifyContent='center'>
            <img src={`/picture/concern-group/${hoverNum}.jpeg`}
              // style={{ height: '90%', maxHeight: 400 }}/>
              style={{
                width: '90%',
                // maxHeight: 400
                borderRadius: 30,
                boxShadow: `0px 0px 12px ${theme.palette.primary.dark}`,
              }}
              alt={`concern-group-${hoverNum}`} />
          </Grid>
        } */}
      </Grid>
    // </MuiFade>
  )
}