import {
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';
import WebAppBar from 'src/components/WebAppBar';
import 'src/App.css';

import Mainpage from 'src/pages/Mainpage';
import NotFoundPage from 'src/pages/NotFoundPage';
import Footer from 'src/components/Footer';
import { useEffect } from 'react';
import FAQ from 'src/pages/FAQ';
import { useMediaQuery } from '@mui/material';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any,
  }
  var API_v1: string;
  var AWS_S3_BUCKET: string;
};

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.tz.setDefault('Asia/Hong_Kong')

function App() {
  const isSmUp = useMediaQuery((theme) => (theme as any).breakpoints.up('sm'));

  const { pathname, hash, key } = useLocation();
  // smoothly scroll to website anchor
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    // to do - fix anchor scrolling
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          window.scrollTo({
            top: element.offsetTop - (isSmUp ? 64 : 56),
            behavior: 'smooth',
          })
        }
      }, 350);
    }    
  }, [pathname, hash, key, isSmUp])
  
  return (
    <>
      <WebAppBar/>
      <Routes>
        <Route path='/'>
          <Route path='' element={<Mainpage />} />
          <Route path='faq' element={<FAQ />} />
          <Route path='*' element={<NotFoundPage />} />
        </Route>
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
