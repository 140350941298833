import { Theme, useMediaQuery } from "@mui/material";


interface ImgResponsiveProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {

  src: string;
  disable1800?: boolean;
  disable1200?: boolean;
}

export default function ImgResponsive({
  src,
  disable1800,
  disable1200,
  ...props
}: ImgResponsiveProps) {
  // const isUp600 = useMediaQuery((theme: Theme) => theme.breakpoints.up(600));
  const isUp900 = useMediaQuery((theme: Theme) => theme.breakpoints.up(900));
  const isUp1200 = useMediaQuery((theme: Theme) => theme.breakpoints.up(1200));
  const isUp1800 = useMediaQuery((theme: Theme) => theme.breakpoints.up(1200));
  
  const imgPathComponent = src.split('.');
  const path = imgPathComponent[0];
  const ext = imgPathComponent[1];

  let srcResponsive = src;
  if (isUp1800 && !disable1800) {
    srcResponsive = `${path}-1800.${ext}`;
  } else if (isUp1200 && !disable1200) {
    srcResponsive = `${path}-1200.${ext}`;
  } else if (isUp900) {
    srcResponsive = `${path}-900.${ext}`;
  } else {
    srcResponsive = `${path}-600.${ext}`;
  }


  return (
    <img
      src={srcResponsive}
      alt={props.alt}
      {...props} />
  )
}