import { Button, Container, Grid, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FadeInAnimation from 'src/components/animation/FadeInAnimation';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { ReactComponent as TextSvg } from 'src/icons/text.svg';
import { ReactComponent as FunctionCycleSvg } from 'src/icons/function/function_cycle.svg';
import { useEffect, useMemo, useRef } from 'react';
import useWindowDimensions from 'src/hooks/use-window-dimensions';
// import FadeInOutAnimation from 'src/components/animation/FadeInOutAnimation';
// import MuiFade from 'src/components/animation/MuiFade';
// import Functionality18 from 'src/components/Functionality18';
import React from 'react';
// import ConcernGroup from 'src/components/homepage/ConcernGroup';
import Formula from 'src/components/homepage/Formula';
import FunIcon from 'src/components/homepage/FunIcon';
// import ConcernGroupExperiment from 'src/components/homepage/ConcernGroupExperiment';
import ParallaxEffect1 from 'src/components/homepage/ParallaxEffect1';
import { Parallax } from 'react-scroll-parallax';
// import { getSrcSet, imgSizes } from 'src/utils/img-attr';
import ImgResponsive from 'src/components/ImgResponsive';

function Video({...props}) {
  const vidRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if(!vidRef.current) { return }
        let playPromise = (vidRef.current as HTMLVideoElement).play();
        if (playPromise !== undefined) {
          playPromise.then(_ => {
            if (entry.isIntersecting) {
              return
            } else {
              (vidRef.current as HTMLVideoElement).pause();
            }
          })
        }
      });
    });
    observer.observe(vidRef.current as Element);
  }, []);

  return (
    <video
      ref={vidRef}
      muted playsInline loop
      webkit-playsinline='true' preload='true' id='bannerVideo'
      style={{
        width: '100%', height: '100%',
        maxWidth: 1800,
        // maxHeight: '90vh'
      }} >
      <source src='/video/30s_full_video.mp4' type='video/mp4'/>
    </video>
  );
}

export const ParallaxSandwichContainer = styled(Container)(({ theme }) => ({
  background: `#FFF`,
  position: 'relative',
  zIndex: 1,
  paddingTop: 20,
  paddingBottom: 20,
  maxWidth: mainpageMaxWidth,
}));

const TitleSeparator = styled('div')(({ theme }) => ({
  width: '30vw',
  height: 3,
  backgroundColor: theme.palette.secondary.dark,
}));

export const mainpageMaxWidth = 1800;

export default function Mainpage() {
  const { t } = useTranslation('mainpage');
  const theme = useTheme();
  const isSmUp = useMediaQuery((theme) => (theme as any).breakpoints.up('sm'));
  const isMdUp = useMediaQuery((theme) => (theme as any).breakpoints.up('md'));
  
  const { width } = useWindowDimensions();
  
  const svgTextWidth = useMemo(() => {
    if (width > 2000) {
      return 2000 / 100 * 25
    }
    return width / 100 * 25
  }, [width]);
  const svgTextWidthMobile = useMemo(() => width / 100 * 80, [width]);
  
  const svgCycleWidth = useMemo(() => width > 900 ? 360 : width / 100 * 40, [width]);
  const svgCycleWidthMobile = useMemo(() => width / 100 * 80, [width]);

  return (
    <React.Fragment>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ paddingTop: 1.5, maxWidth: mainpageMaxWidth }}>
        <Grid
          item container
          xs={12}
          spacing={3}
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          sx={{ marginBottom: 2 }}>
          <Grid item container spacing={1}
            justifyContent='center'>
            <Grid item
              sx={{ position: 'relative' }}>
              {/* manual srcset */}
              <ImgResponsive
                  src='/picture/hero_bg_only.png'
                  alt='product-shot'
                  style={{ width: '100%' }} />
              {/* <FadeInAnimation
                style={{
                  position: 'absolute',
                  top: 0, bottom: 0,
                }}>
                <img src='/picture/hero_product_new_only.png' alt='product-shot'
                  style={{ width: '100%' }} />
              </FadeInAnimation> */}
              <FadeInAnimation
                style={{
                  position: 'absolute',
                  top: 0, bottom: 0,
                  zIndex: 2,
                }}>
                {/* manual srcset */}
                <ImgResponsive
                  src='/picture/hero_product_only.png'
                  alt='product-shot'
                  style={{ width: '100%' }} />
              </FadeInAnimation>
              <FadeInAnimation
                style={{
                  position: 'absolute',
                  top: 0, bottom: 0,
                  transitionDelay: '0.6s',
                  zIndex: 1,
                }}>
                {/* manual srcset */}
                <ImgResponsive
                  src='/picture/hero_new_only.png'
                  alt='product-shot'
                  style={{ width: '100%' }} />
              </FadeInAnimation>
              {/* <FadeInAnimation>
                <img src='/picture/product_bg_with_box_new.png' alt='product-shot'
                  style={{ width: '100%' }} />
              </FadeInAnimation> */}
              {isMdUp &&
                <FadeInAnimation
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: '30%',
                    left: '6%',
                    height: 'fit-content',
                    margin: 'auto',
                    transitionDelay: '0.5s',
                  }}>
                  <TextSvg width={svgTextWidth} height={svgTextWidth}/>
                </FadeInAnimation>
              }
            </Grid>
            {!isMdUp &&
              <Grid item container justifyContent='center'>
                <FadeInAnimation
                  style={{
                    // position: 'absolute',
                    // top: 0,
                    // bottom: '30%',
                    // left: '6%',
                    height: 'fit-content',
                    margin: 'auto',
                    transitionDelay: '0.5s',
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}>
                  <TextSvg width={svgTextWidthMobile}
                    height={svgTextWidthMobile / 3 * 2}
                    />
                </FadeInAnimation>
              </Grid>
            }
            <Grid item container justifyContent='center'>
              <FadeInAnimation
                style={{
                  transitionDelay: '0.7s',
                }}>
                <Typography variant={`${isSmUp ? 'h3' : 'h5'}`} color='primary.dark'
                  sx={{ fontFamily: 'MElleHK-Medium' }}>
                  {t('mainpage.invest_time')}
                </Typography>
              </FadeInAnimation>
            </Grid>
            <Grid item container justifyContent='center'>
              <FadeInAnimation
                style={{
                  transitionDelay: '0.7s',
                }}>
                <Typography variant={`${isSmUp ? 'h5' : 'body1'}`} color='primary.dark'
                  sx={{ fontFamily: 'MElleHK-light' }}>
                  {t('mainpage.invite')}
                </Typography>
              </FadeInAnimation>
            </Grid>
            <Grid item container justifyContent='center'>
              <FadeInAnimation
                style={{
                  transitionDelay: '0.9s',
                }}>
                <a href='https://www.rmshop.com.hk/products/nmn-prime-21000' target='_blank' rel='noreferrer'
                  style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Button variant='outlined' color='secondary'
                    size='large'
                    startIcon={
                      <ShoppingCartCheckoutIcon/>
                    }
                    sx={{
                      '& svg': {
                        color: theme.palette.secondary.main,
                        fontSize: '28px !important',
                      },
                      borderWidth: 3,
                      borderRadius: 10,
                      ':hover': {
                        borderWidth: 3,
                        borderRadius: 10,
                      },
                    }}>
                    <span style={{
                        color: theme.palette.secondary.main,
                        fontFamily: 'MElleHK-Medium',
                        fontSize: 26
                      }}>
                    {t('topbar.buy_now')}
                    </span>
                  </Button>
                </a>
              </FadeInAnimation>
            </Grid>
            {/* <Grid item container justifyContent='center'>
              <FadeInAnimation
                style={{
                  transitionDelay: '0.9s',
                }}>
                <a href='https://www.rmshop.com.hk/products/nmn-prime-21000' target='_blank' rel='noreferrer'
                  style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Button variant='text' color='secondary'
                    size='large'
                    startIcon={
                      <ShoppingCartCheckoutIcon/>
                    }
                    sx={{ '& svg': {
                      color: theme.palette.secondary.main,
                      fontSize: '28px !important',
                    }}}>
                    <span style={{
                        color: theme.palette.secondary.main,
                        fontFamily: 'MElleHK-Medium',
                        fontSize: 26
                      }}>
                    {t('topbar.buy_now')}
                    </span>
                  </Button>
                </a>
              </FadeInAnimation>
            </Grid> */}
            <Grid item container justifyContent='center'
              sx={{
                marginTop: {
                  lg: 5,
                }
              }}>
              <FadeInAnimation
                style={{
                  transitionDelay: '1.1s',
                }}>
                <Video />
                {/* <video autoPlay
                  muted playsInline loop
                  webkit-playsinline='true' preload='true' id='bannerVideo'
                  style={{ width: '100%', maxHeight: '90vh' }} >
                  <source src='/video/30s_full_video.mp4' type='video/mp4'/>
                </video> */}
              </FadeInAnimation>
            </Grid>     
          </Grid>
        </Grid>
      </Container>
      <ParallaxSandwichContainer maxWidth={false} id='formula'>
        <Grid item container justifyContent='center'
          spacing={3}
          sx={{ position: 'relative' }}>
          <Grid item container
            justifyContent='flex-start'
            alignItems='center'
            direction='column'
            spacing={1}
            md={8}
            xs={12}>
            <Grid item>
              <FadeInAnimation style={{ transitionDelay: '0.1s' }}>
                <Typography variant={isSmUp ? 'h3' : 'h5'} color='secondary.dark'
                  sx={{ fontFamily: 'MElleHK-Medium' }}>
                  {t('mainpage.formula.title_0')}
                </Typography>
              </FadeInAnimation>
            </Grid>
            <Grid item>
              <FadeInAnimation style={{ transitionDelay: '0.2s' }}>
                <Typography variant={isSmUp ? 'h3' : 'h5'} color='secondary.dark'
                  sx={{ fontFamily: 'MElleHK-Medium' }}>
                  {t('mainpage.formula.title_1')}
                </Typography>
              </FadeInAnimation>
            </Grid>
            <Grid item>
              <TitleSeparator />
            </Grid>
            <Grid item container
              justifyContent='center'
              alignItems='center'
              spacing={2}>
              <Grid item>
                <FadeInAnimation style={{ transitionDelay: '0.4s' }}>
                  <Typography variant={isSmUp ? 'h4' : 'h6'} color='secondary.dark'
                    sx={{ fontFamily: 'MElleHK-light' }}>
                    {t('mainpage.formula.sub_0')}
                  </Typography>
                </FadeInAnimation>
              </Grid>
              <Grid item>
                <Typography variant={isSmUp ? 'h5' : 'body1'} color='secondary.dark'>
                  ▲
                </Typography>
              </Grid>
              <Grid item>
                <FadeInAnimation style={{ transitionDelay: '0.5s' }}>
                  <Typography variant={isSmUp ? 'h4' : 'h6'} color='secondary.dark'
                    sx={{ fontFamily: 'MElleHK-light' }}>
                    {t('mainpage.formula.sub_1')}
                  </Typography>
                </FadeInAnimation>
              </Grid>
              <Grid item>
                <Typography variant={isSmUp ? 'h5' : 'body1'} color='secondary.dark'>
                  ▲
                </Typography>
              </Grid>
              <Grid item>
                <FadeInAnimation style={{ transitionDelay: '0.6s' }}>
                  <Typography variant={isSmUp ? 'h4' : 'h6'} color='secondary.dark'
                    sx={{ fontFamily: 'MElleHK-light' }}>
                    {t('mainpage.formula.sub_2')}
                  </Typography>
                </FadeInAnimation>
              </Grid>
            </Grid>
            <Formula />
          </Grid>
        </Grid>
      </ParallaxSandwichContainer>
      <Grid item container justifyContent='center'>
        <Grid item>
          <FadeInAnimation>
            {/* manual srcset */}
            {isSmUp &&
              <Parallax speed={-10}>
                <ImgResponsive
                  src='/picture/mood-shot/mood-shot-0.jpg'
                  alt='product-shot-0'
                  style={{ width: '100%', maxWidth: 1800 }} />
              </Parallax>
            }
            {!isSmUp &&
              <ImgResponsive
                src='/picture/mood-shot/mood-shot-0.jpg'
                alt='product-shot-0'
                style={{ width: '100%', maxWidth: 1800 }} />
            }
          </FadeInAnimation>
        </Grid>
      </Grid>

      <ParallaxSandwichContainer maxWidth={false} id='functionality'
        sx={{ paddingTop: 4, paddingBottom: 4 }}>
        <Grid item container justifyContent='center'
          spacing={2}>
          <Grid item container justifyContent='center'
            spacing={2}>
            {/* <Grid item xs={12}>
              <Typography variant='h4' color='secondary.dark' textAlign='center'>
                {t('mainpage.formula.import_0')}
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <FadeInAnimation>
                <Typography variant='h4' color='secondary.dark' textAlign='center'
                  sx={{ fontFamily: 'MElleHK-Medium' }}>
                  {t('mainpage.formula.import_1')}
                </Typography>
              </FadeInAnimation>
            </Grid>
            <Grid item xs={8}>
              <FadeInAnimation>
                <Typography variant='h6' color='secondary.dark' textAlign='center'
                  sx={{ fontFamily: 'MElleHK-Medium' }}>
                  {t('mainpage.formula.import_2')}
                </Typography>
              </FadeInAnimation>
            </Grid>
          </Grid>
          <Grid item container alignItems='center'>
            <Grid item container
              justifyContent={isMdUp ? 'flex-end' : 'center'}
              md={6} xs={12}
              sx={{
                paddingRight: isSmUp ? 2 : 0,
              }}>
              <FadeInAnimation enter={2500}>
                <FunctionCycleSvg
                  height={isSmUp ? svgCycleWidth : svgCycleWidthMobile}
                  width={isSmUp ? svgCycleWidth : svgCycleWidthMobile}
                  />
              </FadeInAnimation>
            </Grid>
            <Grid item container
              justifyContent={isSmUp ? 'flex-start' : 'center'}
              md={6} xs={12}
              spacing={isMdUp ? 2 : 1.5}
              sx={{
                padding: 2,
                height: 'fit-content',
              }}>
              <Grid item xs={12} md={7}>
                <FadeInAnimation enter={2500}>
                  <Typography variant={isSmUp ? 'h6' : 'body1'} color='primary.dark'
                    textAlign={isSmUp ? 'left' : 'center'}
                    sx={{ fontFamily: 'MElleHK-Medium' }}>
                    {t('mainpage.function.text_0')}
                  </Typography>
                </FadeInAnimation>
              </Grid>
              <Grid item xs={12} md={7}>
                <FadeInAnimation enter={2500}>
                  <Typography variant={isSmUp ? 'h6' : 'body1'} color='primary.dark'
                    textAlign={isSmUp ? 'left' : 'center'}
                    sx={{ fontFamily: 'MElleHK-Medium' }}>
                    {t('mainpage.function.text_1')}
                  </Typography>
                </FadeInAnimation>
              </Grid>
              <Grid item xs={12} md={7}>
                <FadeInAnimation enter={2500}>
                  <Typography variant={isSmUp ? 'h6' : 'body1'} color='primary.dark'
                    textAlign={isSmUp ? 'left' : 'center'}
                    sx={{ fontFamily: 'MElleHK-Medium' }}>
                    {t('mainpage.function.text_2')}
                  </Typography>
                </FadeInAnimation>
              </Grid>
            </Grid>
          </Grid>

          <FunIcon />
        </Grid>
      </ParallaxSandwichContainer>

      <Grid item container justifyContent='center'
        sx={{ margin: '20px 0px' }}>
        <Grid item>
          <FadeInAnimation>
            {/* manual srcset */}
            {isSmUp &&
              <Parallax speed={-10}>
                <ImgResponsive
                  src='/picture/mood-shot/mood-shot-1.jpg'
                  alt='product-shot-1'
                  style={{ width: '100%', maxWidth: 1800 }} />
              </Parallax>
            }
            {!isSmUp &&
              <ImgResponsive
                src='/picture/mood-shot/mood-shot-1.jpg'
                alt='product-shot-1'
                style={{ width: '100%', maxWidth: 1800 }} />
            }
          </FadeInAnimation>
        </Grid>
      </Grid>

      {/* <Container maxWidth={false}>
        <Grid item container justifyContent='center'
          id='functionality'
          spacing={2}>
          <Grid item container alignItems='flex-start'
            justifyContent='center'
            spacing={2}
            lg={10}
            sx={{ position: 'relative' }}>
            <Grid item xs={12} md={6}>
              <FadeInAnimation>
                <Functionality18 />
              </FadeInAnimation>
            </Grid>
            <ConcernGroup />
            {isSmUp &&
              <Box sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                }}>
                <img src='/picture/model_right_only.png' alt='model-shot'
                  style={{ width: 'max(20vw, 20vh)' }} />
              </Box>
            }
          </Grid>
          <Grid item container justifyContent='center'>
            <Grid item xs={12}>
              <Typography variant='body1' textAlign='center' color='primary.main'
                sx={{ fontFamily: 'MElleHK-Medium' }}>
                Demo only
              </Typography>
            </Grid>
            <Grid item xs={10}
              sx={{ maxWidth: 400 }}>
              <ConcernGroupExperiment />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' textAlign='center' color='primary.main'
                sx={{ fontFamily: 'MElleHK-Medium' }}>
                Demo only
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container> */}

      {/* <Typography variant='body1' textAlign='center' color='primary.main'
        sx={{ fontFamily: 'MElleHK-Medium' }}>
        Demo - parallax effect
      </Typography> */}
      <ParallaxEffect1 />
      {/* <Typography variant='body1' textAlign='center' color='primary.main'
        sx={{ fontFamily: 'MElleHK-Medium' }}>
        Demo - parallax effect
      </Typography> */}
      
      <Grid item container justifyContent='center'
        sx={{ marginTop: 2, marginBottom: 2 }}>
        <Grid item>
          <FadeInAnimation>
            {/* manual srcset */}
            {isSmUp &&
              <Parallax speed={-10}>
                <ImgResponsive
                  src='/picture/mood-shot/mood-shot-2.jpg'
                  alt='product-shot-2'
                  style={{ width: '100%', maxWidth: 1800 }} />
              </Parallax>
            }
            {!isSmUp &&
              <ImgResponsive
                src='/picture/mood-shot/mood-shot-2.jpg'
                alt='product-shot-2'
                style={{ width: '100%', maxWidth: 1800 }} />
            }
          </FadeInAnimation>
        </Grid>
      </Grid>

      {isSmUp &&
        <ParallaxSandwichContainer sx={{ height: 4 }} />
      }

    </React.Fragment>
  )
}