import './FadeInAnimation.css';
import { useEffect, useRef, useState } from "react";

// will fade in once only
export default function FadeInAnimation({...props}) {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (!isVisible && entry.isIntersecting) {
          setVisible(true);
        }
        // will fade in whenever intersecting version
        // component will fade in every time scrolling down / up & intersecting
        // setVisible(entry.isIntersecting);
      });
    });
    observer.observe(domRef.current as Element);
  }, [isVisible]);

  return (
    <div
      className={`fade-in-section ${
        props.alwaysVisible ? 'is-visible' : (isVisible ? 'is-visible' : '')
      }`}
      style={props.style}
      ref={domRef}>
      {props.children}
    </div>
  );
}