// import { createTheme, Theme } from '@mui/system';
import { createTheme } from '@mui/material/styles';
/* below let theme; theme = createTheme(theme, ...)
 * only works with @mui/material/styles; not @mui/system;
 */
import {
  // Palette, PaletteOptions,
  PaletteColorOptions, PaletteColor
} from '@mui/material/styles'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    disableGrey: Palette['primary'];
    white: Palette['primary'];
    displayGrey: Palette['primary'];
  }
  interface PaletteOptions {
    disableGrey?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
    displayGrey?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    important: Palette;
    foo: string;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    important?: PaletteOptions;
    foo?: string;
  }

  interface Palette {
    tertiary: PaletteColor,
  }

  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
  }
}


export let theme = createTheme({
  palette: {
    primary: {
      main: '#E4C2B9',
      dark: '#AB787D',
      light: '#F3E8E8',
    },
    secondary: {
      main: '#681E32',
      dark: '#5A3330',
      light: '#6D4E46'
    },
    tertiary: {
      main: '#5A3330',
      dark: '#3F210E',
      light: '#671D32',
    },
    error: {
      main: '#FA0D0D',
    },
    disableGrey: {
      main: '#7C7C7C',
      light: '#DADADA',
    },
    white: {
      main: '#FFFFFF'
    },
    displayGrey: {
      main: '#727171',
      light: '#9FA0A0',
    },
  },
  spacing: 10,

  // customized Theme type
  important: {
    primary: {
      main: '#0A3B54 !important'
    },
  },

  // customized Theme variable
  foo: '#FAFAFA',

  // v5 general override
  // applies to all Typography
  typography: {
    // first try out 'Noto Sans CJK TC' for Tranditional Chinese
    // if no match - e.g. English word - try Roboto; & so on
    fontFamily: [
      'Noto Sans CJK TC', 'Roboto',
      // to investgate
      // Helvetica bold is somehow disturbed
      // 'Helvetica',
      'Arial', 'sans-serif',
    ].join(','),
  },

  // v5 component-wise override
  // components: {
  //   MuiTypography: {
  //     styleOverrides: {
  //       h2: {
  //         fontFamily: 'Noto Sans CJK TC'
  //       },
  //       h4: {
  //         fontFamily: 'Noto Sans CJK TC',
  //         fontWeight: 'bold',
  //         fontSize: 32,
  //         color: '#4c4348',
  //         textAlign: 'center'
  //       },
  //       body2: {
  //         fontFamily: 'Noto Sans CJK TC',
  //       }
  //     }
  //   },
  // }
})

theme = createTheme(theme, {
  components: {
    
    ButtonOutline: {
      // border: ... !important to keep its border color in disabled state 
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          height: 36,
          borderRadius: 20,
          [theme.breakpoints.down('sm')]: {
            height: 32,
            borderRadius: 10,
          },
        },
        primary: {
          border: `1px solid ${theme.palette.primary.main} !important`,
          boxShadow: `0px 4px 4px ${theme.palette.primary.main}40`,
          '&:hover': {
            backgroundColor: `${theme.palette.primary.main}33`,
          }
        },
        secondary: {
          border: `1px solid ${theme.palette.secondary.main} !important`,
          boxShadow: `0px 4px 4px ${theme.palette.secondary.main}40`,
          '&:hover': {
            backgroundColor: `${theme.palette.secondary.main}8C`,
          }
        },
        disabled: {
          border: `1px solid ${theme.palette.disableGrey.main} !important`,
          boxShadow: `0px 4px 4px ${theme.palette.disableGrey.main}40`,
        },
        warning: {
          border: `1px solid ${theme.palette.error.main} !important`,
          boxShadow: `0px 4px 4px ${theme.palette.error.main}40`,
          color: theme.palette.error.main,
          borderColor: theme.palette.error.main,
          '&:hover': {
            backgroundColor: `${theme.palette.error.main}73`,
            borderColor: theme.palette.error.main,
          }
        },
      },
    },

    CustomCircularProgress: {
      styleOverrides: {
        root: {
          height: '24px !important',
          width: '24px !important',
        },
        primary: {
          color: theme.palette.primary.main,
        },
        secondary: {
          color: theme.palette.primary.dark,
        },
        disabled: {
          color: theme.palette.disableGrey.main,
        },
        warning: {
          color: theme.palette.warning.main,
        },
        filledImportant: {
          color: '#FFFFFF !important'
        },
      },
    },

    ButtonPrimary: {
      styleOverrides: {
        root: {
          height: 36,
          borderRadius: 20,
          [theme.breakpoints.down('sm')]: {
            height: 32,
            borderRadius: 10,
          },
        },
        primary: {
          color: '#FFFFFF',
          backgroundColor: `${theme.palette.primary.main} !important`,
          boxShadow: `0px 4px 4px ${theme.palette.primary.main}40`,
          '&:hover': {
            backgroundColor: `${theme.palette.primary.main}8C`,
          }
        },
        secondary: {
          color: '#FFFFFF',
          backgroundColor: `${theme.palette.secondary.main} !important`,
          boxShadow: `0px 4px 4px ${theme.palette.secondary.main}40`,
          '&:hover': {
            backgroundColor: `${theme.palette.secondary.main}8C`,
          }
        },
        disabled: {
          color: theme.palette.disableGrey.main,
          backgroundColor: `${theme.palette.disableGrey.light} !important`,
          boxShadow: `0px 4px 4px ${theme.palette.disableGrey.light}40`,
        },
        warning: {
          color: '#FFFFFF',
          backgroundColor: `${theme.palette.error.main} !important`,
          boxShadow: `0px 4px 4px ${theme.palette.error.main}40`,
          '&:hover': {
            backgroundColor: `${theme.palette.error.main}73`,
          }
        },
      },
    },

    ButtonTwoLine: {
      styleOverrides: {
        root: {
          height: 54,
          borderRadius: 23,
          paddingLeft: 32,
          paddingRight: 32,
          [theme.breakpoints.down('sm')]: {
            height: 42,
            borderRadius: 18,
          },
        },
        primary: {
          color: '#FFFFFF',
          backgroundColor: `${theme.palette.primary.main} !important`,
          boxShadow: `0px 4px 4px ${theme.palette.primary.main}40`,
          '&:hover': {
            backgroundColor: `${theme.palette.primary.main}8C`,
          }
        },
        secondary: {
          color: '#FFFFFF',
          backgroundColor: `${theme.palette.secondary.main} !important`,
          boxShadow: `0px 4px 4px ${theme.palette.secondary.main}40`,
          '&:hover': {
            backgroundColor: `${theme.palette.secondary.main}8C`,
          }
        },
        disabled: {
          color: theme.palette.disableGrey.main,
          backgroundColor: `${theme.palette.disableGrey.light} !important`,
          boxShadow: `0px 4px 4px ${theme.palette.disableGrey.light}40`,
        },
        warning: {
          color: '#FFFFFF',
          backgroundColor: `${theme.palette.error.main} !important`,
          boxShadow: `0px 4px 4px ${theme.palette.error.main}40`,
          '&:hover': {
            backgroundColor: `${theme.palette.error.main}73`,
          }
        },
        buttonLabelConfig: {
          display: 'flex',
          flexDirection: 'column',
        }
      },
    },

    MenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'red',  
          },
          "&.Mui-focused": {
            color: "red"
          },
          "&$selected": {       // this is to refer to the prop provided by M-UI
            backgroundColor: "black", // updated backgroundColor
          },
        },
        selected: {
          backgroundColor: 'red',
          color: 'red',
        },
      }
    },
  }
})
